// Uppy and its plugins
const Uppy = require("@uppy/core");
const XHRUpload = require("@uppy/xhr-upload");
const Dashboard = require("@uppy/dashboard");
const German = require("@uppy/locales/lib/de_DE");

// Uppy Docs: https://uppy.io/docs/uppy/
// Captcha Docs: https://developers.google.com/recaptcha/intro
// Test-Keys
// https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do

// Uppy configuration
const uppy = Uppy({
  restrictions: {
    // TODO: Extract to config?
    allowedFileTypes: [
      ".mp4",
      ".m4p",
      ".m4v",
      ".wmv",
      ".mov",
      ".qt",
      ".avi",
      ".mkv",
      ".webm",
      ".ogg",
      ".ogv",
    ],
    maxFileSize: 10485760,
    maxNumberOfFiles: 1,
  },
  meta: {
    recaptcha: "",
  },
  locale: German,
})
  .use(Dashboard, {
    inline: true,
    width: 1140,
    height: 400,
    target: "#fileupload",
    hideUploadButton: true,
    showLinkToFileUploadResult: false,
    proudlyDisplayPoweredByUppy: false,
  })
  .use(XHRUpload, {
    endpoint: "/upload.php",
    fieldName: "uppy_file",
    metaFields: ["name", "recaptcha"],
  });

// Google Recaptcha config
window.loadCaptcha = () => {
  grecaptcha.render("captcha", {
    callback: (gRecaptchaResponse) => {
      // Runns when captcha got solved
      uppy.setMeta({ recaptcha: gRecaptchaResponse });
      uppy.getPlugin("Dashboard:StatusBar").setOptions({
        hideUploadButton: false,
      });
    },
    "expired-callback": () => {
      // Runs when captcha expires
      // Revert changes!
      uppy.setMeta({ recaptcha: "" });
      uppy.getPlugin("Dashboard:StatusBar").setOptions({
        hideUploadButton: true,
      });
    },
  });
};
